.fade-in-bottom {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInBottom 0.8s ease-out forwards;
}

@keyframes fadeInBottom {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hidden {
  opacity: 0;
  /* filter: blur(2px); */
  transform: translateY(20px);
  scale: 0.4px;
  transition: all 3s;
  width: 100%;
}

.show {
  opacity: 1;
  filter: blur(0);
  scale: 1;
  transform: translateY(0);
}
.heading {
  background: rgb(0, 109, 119);
  background: linear-gradient(
    90deg,
    rgba(0, 109, 119, 0.9192051820728291) 12%,
    rgba(130, 254, 255, 0.711922268907563) 61%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-2 {
  background: rgb(1, 127, 139);
  background: linear-gradient(
    90deg,
    rgba(1, 127, 139, 0.9192051820728291) 44%,
    rgba(34, 87, 122, 1) 55%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* CustomCarousel.css */
.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 900px;
}

.carousel {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slide {
  flex: 0 0 100%;
  transition: transform 0.5s ease;
}

.slide img,
.slide video {
  width: 100%;
  height: auto;
}

.active {
  transform: translateX(0);
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 24px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}
.slick-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Increase the size of individual dots */
.slick-dots li button::before {
  content: "•"; /* Use a larger dot character */
  font-size: 18px; /* Adjust the font size to increase dot size */
  color: #fff; /* Customize the dot color */
  opacity: 0.8; /* Adjust the opacity if needed */
  transition: all 0.3s; /* Add a transition for a smoother effect */
}

/* Style the active dot */
.slick-dots li.slick-active button::before {
  opacity: 0.9; /* Increase opacity for the active dot */
  font-weight: bold; /* Optionally, make the active dot bold */
}
.underlineText {
  color: #e89f69;
  text-decoration-line: underline;
  text-decoration-color: #e89f69;

  text-decoration-thickness: 2px;
}
