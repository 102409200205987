/* CustomCarousel.css */
.image-slider {
  position: relative;
  width: 100%;
  /* height: 80vh; */
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  /* height: 60vh; */
  padding-bottom: 45.3%;
  margin-bottom: -6px;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* height: 80vh; */
  object-fit: cover;
}
.text-overlay {
  position: absolute;
  /* bottom: 50px;
  left: 30px; */
  /* background-color: rgba(255, 255, 255, 0.7); */
  padding: 5px;
  /* color: white; */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5);  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.construction-text {
  color: #fff;
  font-size: 24px;
  text-align: center;
  padding: 20px;
}
/* .carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 900px;
} */

/* .carousel {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slide {
  flex: 0 0 100%;
  transition: transform 0.5s ease;
} */

/* .slide img,
.slide video {
  width: 100%;
  height: auto;
}

.active {
  transform: translateX(0);
} */
/* 
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 24px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
} */

.slick-dots {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.slick-dots li button::before {
  content: "•";
  font-size: 18px;
  color: #fff;
  opacity: 0.8;
  transition: all 0.3s;
}

.slick-dots li.slick-active button::before {
  opacity: 0.9;
  font-weight: bold;
}
