.slider {
  width: 100%;
  /* Set a maximum width for the carousel */
  margin: 0 auto; /* Center the carousel */
}

.slide,
.slide img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 15px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.21612394957983194) 100%,
    rgba(255, 255, 255, 0) 100%
  );
}
.slide {
  opacity: 0.8;
  transform: scale(0.8);
  filter: blur(2px);
  border-radius: 15px;
}

.slide-active {
  opacity: 1;
  transform: scale(1.1);
  filter: blur(0);
  transition: all 0.5s ease-in-out;
  border-radius: 15px;
}
