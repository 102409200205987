/* ChatButton.css */
.chat-button {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.chat-button:hover {
  background-color: #128c7e;
  transform: scale(1.2);
  transition: ease 0.5s;
}

.chat-icon {
  margin-right: 10px;
  font-size: 30px;
}

/* .chat-text {
  display: inline-block;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}

.chat-button:hover .chat-text {
  animation: bounce 0.3s infinite alternate;
} */

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media (max-width: 992px) {
  .chat-icon {
    margin-right: 0;
  }
  .chat-text {
    display: none;
  }
}
