@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.animated-box {
  transform-origin: center;
}

.pop {
  animation: popAnimation 0.5s ease-out;
}

@keyframes popAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* .animated-flex-box {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.9s ease;
}

.animated-flex-box.visible {
  opacity: 1;
  transform: translateY(0);
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
  width: 100%;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
} */
