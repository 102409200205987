.partner-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 350px;
  height: 300px;
  /* background: #edf6f9; */
  /* background-color: #1d1e22e5; */
  background-color: rgba(255, 255, 255, 0.9);
  /* background: rgba(255, 255, 255, 0.9); */
  border-radius: 20px;
  box-shadow: 0 35px 80px rgba(0, 0, 0, 0.15);
  transition: 0.5s;
}
.partner-card:hover {
  height: 300px;
}

.partner-card .imgBox {
  position: absolute;
  top: 20px;
  width: 300px;
  height: 220px;
  background: #333;
  border-radius: 12px;
  overflow: hidden;
  object-fit: cover; /* Update object-fit property */
  transition: 0.5s;
}

/* Add this style to make the image responsive */
.partner-card .imgBox img {
  width: 100%;
  height: 100%;
}
.partner-card:hover .imgBox {
  top: -100px;
  scale: 0.75;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}
.partner-card .content {
  position: absolute;
  top: 252px;
  width: 100%;
  height: 35px;
  padding: 0 30px;
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
  font-family: "Oxygen";
}

.partner-card:hover .content {
  top: 130px;
  height: 250px;
}
.partner-card .content h2 {
  font-size: 1.5em;
  font-weight: 700;
  color: #33d6ff;

  font-family: "Oxygen";
}
.partner-card .content p {
  color: #333;
  margin-top: 10px;
  font-weight: "bold";
}
