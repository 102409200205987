.card {
  position: relative;
  height: 300px;
  width: 20vw;
  margin: 35px;
  min-width: 300px;
  box-shadow: 5px 5px 20px black;
  overflow: hidden;
}

.card img {
  height: 300px;
  width: 20vw;
  min-width: 300px;
  border-radius: 3px;
  object-fit: cover;
  position: relative;
  display: block;
}

.card .intro {
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  height: 80px;
  width: 20vw;
  min-width: 300px;
  padding: 6px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  background: rgb(27, 27, 27, 0.5);
  color: white;
  transition: 0.5s;
  text-align: center;
}
.card h1 {
  display: flex;
  /* margin-top: 10px; */
  font-size: 25px;
  font-family: "Oxygen";
  justify-content: center;
  font-weight: bold;
}

.card p {
  text-align: center;
  font-size: 20px;
  margin: 20px;
  visibility: hidden;
  opacity: 0;
}

span {
  font-weight: bold;
}

.card button:hover {
  color: aliceblue;
  background-color: #1a6985; /* Darker green on hover */
  transform: scale(1.05); /* Scale button on hover */
}

.card:hover {
  cursor: pointer;
}

.card:hover .intro {
  height: 180px;
  background: black;
}
.card:hover p {
  opacity: 1;
  visibility: visible;
}

/* .card:hover img {
  transform: scale(1.1) rotate(-3deg);
} */
