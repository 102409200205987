.animated-section {
  overflow: hidden;
  position: relative;
}

.animated-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transform-origin: left center;
  animation: unroll 0.8s forwards; /* Apply the unroll animation */
  z-index: -1; /* Ensure it's behind the content */
}

@keyframes unroll {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.animated-section .text {
  position: relative; /* Ensure content is on top */
  z-index: 1; /* Ensure it's on top of the pseudo-element */
}
.highlight {
  background-image: linear-gradient(to right, #f27121cc, #e94057cc, #8a2387cc);
  border-radius: 6px;
  padding: 3px 6px;
}
