.nav-bar {
  display: flex;
  padding: 2px 0px;
  /* background-color: #006d77; */
  background-color: white;
  font-family: "Oxygen";
  /* height: 10vh; */
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); */
}
.nav-logo img {
  /* background-color: red; */
  /* margin-left: 20px; */
  /* height: 15vh; */

  width: 200px;
}
.nav-links {
  flex: 1;
  margin: auto 20px;
}
.nav-links ul {
  margin-left: 20px;
  display: inline;
}
.nav-links ul li {
  list-style: none;
  display: inline-block;
  padding: 4px 25px;
  font-weight: bolder;
}
.nav-links ul a {
  color: #006d77;
  text-decoration: none;
  font-size: 15px;
}
.nav-links ul li::after {
  content: "";
  width: 0;
  height: 2px;
  background: #e29578;
  display: block;
  margin: auto;
  transition: 0.5s;
}
.nav-links ul li:hover::after {
  width: 100%;
}
.nav-links ul li.active::after {
  width: 100%;
}
@media screen and (max-width: 1167px) {
  .nav-bar {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); */
  }
  .nav-logo img {
    /* margin-left: 10px; */
    /* height: 15vh; */
    width: 200px;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    /* position: absolute;
    left: 0;
    right: 0; */
    /* background: #006d77; */
    z-index: 1;
  }

  .nav-links.show {
    display: flex;
  }

  .nav-links ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
    box-shadow: 100px;
  }

  .nav-links ul li {
    text-align: center;
    padding: 10px;
    position: relative; /* Added position relative */
    margin: 0 auto;
  }

  .nav-links ul li::after {
    content: "";
    width: 0;
    height: 2px;
    background: #e29578;
    display: block;
    margin: auto;
    transition: 0.5s;
    position: absolute;
    bottom: 1;
    left: 50%; /* Position it at the center of the li */
    transform: translateX(-50%); /* Center it horizontally */
  }
  .nav-links ul li:hover::after,
  .nav-links ul li.active::after {
    width: 100%; /* Make the hover effect cover the entire width */
  }
  .nav-icon {
    font-size: 20px; /* Adjust this value to make the hamburger icon bigger */
    cursor: pointer;
    font-weight: bolder;
  }
}
